import {VALIDATION_REG_EXP} from '@/const';

/**
 * Get correct type of constructor
 * @param {constructor} fn
 * @return {string|string}
 */
function getType(fn) {
  const match = fn && fn.toString().match(/^\s*function (\w+)/);
  return match ? match[1] : '';
}

/**
 * Check is both types are same
 * @param {constructor} type - function constructor.
 * @param {constructor} expectedType - name of expected constructor: Array, Number, Object etc.
 * @return {boolean}
 */
export function isSameType(type, expectedType) {
  return getType(type) === getType(expectedType);
}

/**
 * Function to load script from url.
 * @param {string} url -  Download url.
 * @param {function|undefined} cb - onLoad callback.
 */
export function loadScript(url, cb) {
  const tag = document.createElement('script');
  tag.src = url;
  tag.onload = () => cb?.();
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

/**
 * Separate abortSignal from request params
 * @param {object} params
 * @return {{token: ({abortSignal}|undefined), props:  object}}
 */
export function ejectAbortSignal(params) {
  let token = undefined;
  if (params.abortSignal) {
    token = params.abortSignal;
    delete params.abortSignal;
  }
  return {
    token,
    props: params,
  };
}

/**
 * Get from Event Object home team and away team data object
 * @param {object} event
 * @param {Array.<Object>} event.participants
 * @param {String|Number} event.home_team_id
 * @return {{awayTeam: {}, homeTeam: {}}}
 */
export function getAwayHomeTeams({
  participants,
  home_team_id: homeTeamId,
} = {}) {
  const homeId = homeTeamId;
  const homeTeam = {};
  const awayTeam = {};

  participants?.forEach((team) => {
    let teamObj;
    if (homeId) {
      teamObj = Number(team.id) === Number(homeId) ? homeTeam : awayTeam;
    } else {
      teamObj = Object.keys(awayTeam).length ? homeTeam : awayTeam;
    }
    const normalizedProperties = {};
    Object.entries(team.properties || {}).forEach(([key, val]) => {
      normalizedProperties[key.toLowerCase()] = val;
    });
    teamObj.name = team.name;
    teamObj.img = team.images;
    teamObj.shortName = team.short_name;
    teamObj.alias = team.alias;
    teamObj.gender = normalizedProperties.gender?.[0];
    teamObj.sport = normalizedProperties.sport?.[0];
    teamObj.color = team.color;
    teamObj.id = team.id;
  });

  return {
    homeTeam,
    awayTeam,
  };
}

/**
 * Get regular string and
 * return string consists from substring divided by separator and wrapped to html tag
 * @param {string} str
 * @param {string} separator
 * @param {string} tag
 * @return {string}
 */
export function parseStringBySeparatorToHtml(str, separator = '\r\n', tag = 'p') {
  if (!str) return '';
  const strArr = str.split(separator);
  return strArr.reduce((total, next) => {
    return `${total}<${tag}>${next}</${tag}>`;
  }, '');
}

/**
 * @param {string} string
 * @return {string}
 */
export function upperCaseTheFirstLetter(string) {
  return string.toLowerCase().replace(/(^\w{1})|((\s|-)+\w{1})/g, (letter) => {
    return letter.toUpperCase();
  });
}

/**
 * @param {string} email
 * @return {boolean}
 */
export function validateEmail(email) {
  return VALIDATION_REG_EXP.email.test(email) || VALIDATION_REG_EXP.numberEmail.test(email);
}

/**
 * @param {string} name
 * @return {string}
 */
export function teamName(name) {
  return name.replace('University of', '').replace('University', '').trim();
}

/**
 * @param {string} dateString
 * @return {Date}
 */
export function getNewDateFromString(dateString) {
  return new Date(dateString.replace(/-/g, '/'));
}

/**
 * Converting seconds into human-understandable time (HH:mm:ss || mm:ss)
 * @param {Number} seconds
 * @param {Boolean} hoursNeed
 * @return {string}
 */
export function timeToHumanFormat(seconds, hoursNeed = false) {
  const secondsInHour = 3600;
  const withHours = hoursNeed || Boolean(Math.trunc(seconds / secondsInHour));
  const time = new Date(0);
  time.setHours(0, 0, seconds, 0);

  const parsedTime = time.toLocaleString(undefined, {
    second: '2-digit',
    minute: '2-digit',
    hour: '2-digit',
    hourCycle: 'h23',
  });
  const newArr = parsedTime.split(/\D/).reverse();
  newArr.length = withHours ? 3 : 2;
  return newArr.reverse().join(':');
}

/**
 * Download data
 * @param {string} url
 * @param {string} name
 */
export function initDownload(url, name = 'download') {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
  a.remove();
}

/**
 * Parse human-understandable time to seconds
 * @param {string} timeString ( hh:mm:ss )
 * @param {string} separator
 * @return {number} seconds
 */
export function secondsFromString(timeString, separator = ':') {
  const [ss = 0, mm = 0, hh = 0] = timeString?.split?.(separator)?.reverse?.();
  const zero = new Date(0);
  zero.setHours(0, 0, 0, 0);

  const D = new Date(0);
  D.setHours(hh, mm, ss, 0);

  return (D - zero) / 1000;
}

/**
 * Update (delete or add) Vue router query params
 * @param {object} route
 * @param {object} router
 * @param {string} queryName
 * @param {string} queryValue
 */
export function updateRouteQuery(route, router, queryName, queryValue) {
  const routParams = {...route.query};
  queryValue ? (routParams[queryName] = String(queryValue)) : (delete routParams[queryName]);

  !_.isEqual(route.query, routParams) &&
  router.push({query: routParams}).catch((err) => {
    console.log('err', err);
  });
}

/**
 * get prop from array of objects recursively;
 * @param {array} array
 * @param {string} childPropName
 * @param {string} searchPropName
 * @return {string[]}
 */
export function recursivelyGetProps(array, childPropName = 'children', searchPropName = 'id') {
  return array.reduce((total, next) => {
    const arr = [];
    next[searchPropName] && arr.push(next[searchPropName]);
    next[childPropName] && arr.push(...recursivelyGetProps(
        next[childPropName],
        childPropName,
        searchPropName,
    ));
    total.push(...arr);
    return total;
  }, []);
}

/**
 * create recursively Object with key = item.id value = item;
 * @param {array} arr
 * @return {{}}
 */
export function generateIdsObj(arr) {
  const data = {};
  arr.forEach((category) => {
    data[category.id] = category;
    if (category.children?.length) {
      Object.assign(data, generateIdsObj(category.children));
    }
  });
  return data;
}

/**
 * @return {string}
 */
export function generateUUID() {
  const timestamp = new Date().valueOf();
  return ('xxxxxxxx-xxxx-xxxx-' + timestamp).replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * Trigger api key error
 * @param {boolean} bool
 * @param {string} url
 */
export function apiKeyErrorEvent(bool, url) {
  window.document.dispatchEvent(
      new CustomEvent('api_key_error', {
        detail: {
          error: bool,
          url,
        },
      }),
  );
}

/**
 * Retrieves a nested value from an object based on a string path.
 *
 * @param {string} strPath - The string path to the nested value, with keys separated by dots.
 * @param {Object} object - The object from which to retrieve the nested value.
 * @return {*} - The value at the specified path, or undefined if the path does not exist.
 */
export function getNestedValue(strPath, object) {
  return strPath.split('.').reduce((acc, key) => acc?.[key], object);
};

/**
 * This function combines fields by a specified name from an array of objects.
 * It creates a new object for each unique name and combines the specified fields
 * into an array.eslint-disable-next-line
 *
 * @param {Array.<Object>} initArray - The initial array of objects to process.
 * Default is an empty array.
 * @param {string} resultFieldName - The name of the field in the resulting objects where
 * the combined fields will be stored.
 * @param {Array.<string>} concatFields - An array of field names to combine into the result field.
 * @param {string} concatObjectByFieldName - The name of the field in the initial objects
 * to use for combining. Default is 'name'.
 * @return {Array.<Object>} An array of objects with combined fields.
 */
export function combineFieldsByName({
  initArray = [],
  resultFieldName,
  concatFields = [],
  concatObjectByFieldName = 'name',
}) {
  const mapByName = {};
  initArray.forEach((item) => {
    const concatField = item[concatObjectByFieldName];
    if (!mapByName[concatField]) {
      mapByName[concatField] = {
        ...item,
        [resultFieldName]: [],
      };
    }
    const obj = {};
    (concatFields.length? concatFields : Object.keys(item)).forEach((field) => {
      obj[field] = item[field];
      if (item['className']) {
        mapByName[concatField]['className'] = item['className'];
      }
    });

    Object.keys(obj).length && mapByName[concatField][resultFieldName].push(obj);
  });
  return Object.values(mapByName);
}
