import {
  GET_MERGED_PASSES_BY_NAME,
  GET_PASSES_WITH_ACCESSES,
  HAS_PASSES_TO_SHOW,
  PREPARE_SELECTED_PASSES,
} from '@/store/getterTypes';
import {
  COMBINED_PASS_FIELD_NAME, CURRENCY_SYMBOLS,
  ORDER_ACTIVITY_STATUS,
  ORDER_STATUS,
  ORDER_TYPE,
} from '@/const';
import participant from '@/store/participant/participant';
import channel from '@/store/channel/channel';
import {combineFieldsByName} from '@/utils/helpers';

const getPriceTitle = (pass) => {
  let period = '';
  if (pass.offer) {
    const periodCount = pass.period > 1 ? pass.period : '';
    period = `/${periodCount}${pass.offer}`;
  }
  const currency = channel.state.settings?.currency;
  return `${currency} ${CURRENCY_SYMBOLS[currency]}${pass.base_price}${period}`;
};
export default {
  [GET_PASSES_WITH_ACCESSES]: (state, getters) => {
    return getters[PREPARE_SELECTED_PASSES]({
      packages: state.packagesList,
      subscriptions: state.subscriptionsList,
    });
  },
  [GET_MERGED_PASSES_BY_NAME]: (state, getters) => {
    const passes = getters[GET_PASSES_WITH_ACCESSES];
    return combineFieldsByName({
      initArray: passes,
      resultFieldName: COMBINED_PASS_FIELD_NAME,
      concatObjectByFieldName: 'tag',
    });
  },
  [HAS_PASSES_TO_SHOW]: (state, getters) => {
    return Boolean(getters[GET_PASSES_WITH_ACCESSES].length);
  },
  [PREPARE_SELECTED_PASSES]: (state) => ({
    packages = [],
    subscriptions = [],
    events = [],
  }) => {
    // currently expected for 1 category and not expected team-pass type;
    const packagesList = Object.fromEntries(
        packages.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.package,
          priceTitle: getPriceTitle(item),
        }]));
    const subscriptionsList = Object.fromEntries(
        subscriptions.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.subscription,
          priceTitle: getPriceTitle(item),
        }]));
    const eventsList = Object.fromEntries(
        events.map((item) => [item.id, {
          ...item,
          purchased: false,
          passType: ORDER_TYPE.event,
          priceTitle: getPriceTitle(item),
        }]));
    const orders = [...state.ordersList];

    orders.forEach((order) => {
      if (
        order.status === ORDER_STATUS.paid &&
        order.activity_status === ORDER_ACTIVITY_STATUS.active
      ) {
        if (order.type === ORDER_TYPE.subscription) {
          subscriptionsList[order.product.id] &&
          (subscriptionsList[order.product.id].purchased = true);
        } else if (order.type === ORDER_TYPE.package) {
          const currentPackage = packagesList[order.product.id];
          if (!currentPackage) return;
          const categoryId = order.product.event_category_id;
          const participantId = order.product.participant_id;

          if (!currentPackage.disabledCategories) {
            currentPackage.disabledCategories = [];
          }
          categoryId && currentPackage.disabledCategories.push(categoryId);

          if (!currentPackage.disabledParticipants) {
            currentPackage.disabledParticipants = [];
          }
          participantId && currentPackage.disabledParticipants.push(participantId);

          if (currentPackage.is_team_pass) {
            currentPackage.purchased = participant.state.participantsList.length ===
              currentPackage.disabledParticipants.length;
          } else {
            currentPackage.purchased = Object.keys(currentPackage.event_categories).length ===
              currentPackage.disabledCategories.length;
          }
        } else if (order.type === ORDER_TYPE.event) {
          eventsList[order.product.id] &&
          (eventsList[order.product.id].purchased = true);
        }
      }
    });
    return [
      ...subscriptions.map(({id}) => subscriptionsList[id]),
      ...packages.map(({id}) => packagesList[id]),
      ...events.map(({id}) => eventsList[id]),
    ].sort((a, b) => b.weight - a.weight);
  },
};
